var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"xl":"2","lg":"2","md":"2","sm":"12"}},[_c('v-select',{attrs:{"reduce":function (item) { return item; },"options":_vm.yearList,"placeholder":"Năm"},on:{"input":_vm.filterByYear}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"xl":"4","lg":"4","md":"4","sm":"12"}},[_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.dataComboboxBusiness,"placeholder":"Doanh nghiệp"},on:{"input":_vm.filterBusiness}})],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center mb-1",attrs:{"xl":"6","lg":"6","md":"6","sm":"12"}},[_c('search',{staticClass:"search-input",on:{"change":_vm.search}})],1)],1),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'dateSend')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(props.row.dateSend)))])]):(props.column.field === 'submitReportStatusString')?_c('span',[_c('b-badge',{staticClass:"border-status",attrs:{"pill":"","variant":props.row.submitReportStatus ==='Send' ? 'warning' : 'primary'}},[_c('span',[_vm._v(" "+_vm._s(props.row.submitReportStatusString))])])],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }