<template>
  <div
    class="team"
  >
    <b-row>
      <b-col
        xl="2"
        lg="2"
        md="2"
        sm="12"
        class="mb-1"
      >
        <v-select
          :reduce="item => item"
          :options="yearList"
          placeholder="Năm"
          @input="filterByYear"
        />
      </b-col>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
        class="mb-1"
      >
        <v-select
          :reduce="item => item.id"
          label="name"
          :options="dataComboboxBusiness"
          placeholder="Doanh nghiệp"
          @input="filterBusiness"
        />
      </b-col>
      <b-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        class="d-flex justify-content-end align-items-center mb-1"
      >
        <search
          class="search-input"
          @change="search"
        />
      </b-col>
    </b-row>

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >

      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->
        <span v-if="props.column.field === 'dateSend'">
          <span>{{ props.row.dateSend | formatDate }}</span>
        </span>
        <span v-else-if="props.column.field === 'submitReportStatusString'">
          <b-badge
            pill
            :variant="props.row.submitReportStatus ==='Send' ? 'warning' : 'primary'"
            class="border-status"
          >
            <span> {{ props.row.submitReportStatusString }}</span>
          </b-badge>
        </span>

        <!-- Column: trích yếu -->

      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
  </div>
  <!-- End Team Section -->

</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge,
  BMedia,
  BDropdown,
  BCol,
  BRow,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import Carousel from './Carousel.vue'
import SiteFooter from './SiteFooter.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import SiteHeader from './SiteHeader.vue'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    BCol,
    BRow,
    SiteHeader,
    Carousel,
    VBTooltip,
    SiteFooter,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      tokenCheck: localStorage.getItem('accessToken'),
      formatFullName,
      columns: [
        {
          label: 'MÃ DOANH NGHIỆP',
          field: 'bussinessCode',
          sortable: false,
        },
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY NỘP',
          field: 'dateSend',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'TÌNH TRẠNG',
          field: 'submitReportStatusString',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        year: 0,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      isActive: false,
      userId: '',
      dataList: [],
      dataComboboxBusiness: [],
    }
  },

  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  created() {
    this.getYear()
    this.urlQuery.year = this.year
    this.fetchData(this.urlQuery)
    this.fetchCombobox()
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.REPORT_SEND, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSINESS)
      this.dataComboboxBusiness = data?.data
    },
    filterBusiness(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchData(this.urlQuery)
      }
    },
    filterByYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchData(this.urlQuery)
      }
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">

#report {
  .vgt-table {
    min-width: max-content;
    th {
      background-color: #0282CD;
      color: #fff !important;
    }
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
  // .filter-search-worker{
  //   width: 400px;
  // }
}
@media (max-width: 1369px){
  #report {
  .search-input {
    width: 100%;
  }
  // .filter-search-worker{
  //   width: 100%;
  // }
  .ml-4 {
      margin-left: 1rem !important;
  }
  .justify-content-end{
    justify-content: flex-start !important;
    margin-left: 0px !important;
  }
}
}
</style>
